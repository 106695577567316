@import "../../node_modules/@crossroads/shop-views/src/shop-views/AdventCalendarHero";
@import "theme/ui-components/_variables.scss";

.hero {
  margin-top: -#{size("header-margin")};
  height: 400px;
}
.heroContent {
  max-width: 900px;
  padding: size("gutter") * 2;
  padding-left: size("gutter") * 3;
  padding-right: size("gutter") * 3;
  width:100%;
  background-color: #fff;
  position: relative;
  top: -77px;
  margin:0 auto -#{size("gutter--large")};
  text-align: center;
  h1 {
    margin-top: 0;
    font-size: size("font--huge");
    color: color("secondary");
  }
  p {
    font-size: size("font--medium");
  }
}
