@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$imageWidthIncrease: 10px;

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  overflow: hidden;
  text-decoration: none;
  transition: transform transition("duration--atoms") transition("ease-in"),
              box-shadow transition("duration--atoms") transition("ease-in");

  border-radius: size("border-radius");

  &:not(.dummy):hover {
    text-decoration: none;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);

    .name {
      text-decoration: underline;
    }
  }
}

.header {
  overflow: hidden;
  background: color("disabled");
}

.imageWrapper {
  transition: transform transition("duration--molecules") transition("ease-ease");
}

.image {
  position: absolute;
  width: 100%;
}

.body {
  padding: size("gutter");
  line-height: font("line-height--heading");
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // use static value to allow dummy cards
  // TODO: calculate in SASS?
  // height: 148px; old height from point feature
  height: 115px;
  flex: 1 0 auto;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand, .price, .splitPrice {
  font-size: size("font--small");
  line-height: 1;
}

.name {
  font-weight: 600;
  color: color("text");
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.brand {
  color: color("text--muted");
  margin-top: size("gutter--mini") / 2;
  margin-bottom: size("gutter--small");
}

.priceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.price {
  font-weight: bold;
  color: color("text");
  margin-top: 0;
  margin-bottom: 0;

  &.showMSRP {
    color: color("secondary");
  }

  & > span {
    color: color("text");
    text-decoration: line-through;
  }
}

.discount {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50px;
  width: 50px;
  color: #fff;
  background: color("secondary");
  background: linear-gradient(90deg, color("primary") 0%, color("secondary") 100%);
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  margin: 0;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.dummy {
  .image {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  span, p {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  .name {
    max-width: 50%;
  }

  .brand {
    max-width: 53%;
  }

  .price {
    max-width: 42%;
  }
}

.colors {
  display: flex;
  justify-content: center;
  position: absolute;
  top: size("gutter--mini");
  z-index: 1;
  left: 0;
  right: 0;

  & > div {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    margin: 0 size("gutter--mini") / 2;
  }
}
