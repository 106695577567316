@import "theme/ui-components/_variables.scss";

$logoWidth: 160px;
$logoHeight: 35px;
$logoWidthLarge: 213px;
$logoHeightLarge: 46px;

.block {
  font-size: size("font--small");
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  height: 100%;

  & + & {
    margin-left: size("gutter--large");
  }
}

.logo {
    width: 86px;
    height: 24px;
    top: -3px; // Offset to make up for weird viewBox

    @media (min-width: breakpoint("small")){
      width: size("logo-width");
      height: 46px;
  }
}

.logoCards {
  height: 25px;
  margin-left: size("gutter");
  object-fit: cover;

  @media (min-width: breakpoint("small")) {
    height: 35px;
    margin-left: size("gutter--large");
  }
}
