@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
}

.categories {
  display: flex;
  justify-content: space-between;

  & > * {
    width: 25%;
    padding: 0 size("gutter--small");

    @media (max-width: breakpoint("medium") - 1px) {
      width: 25%;
    }

    @media (max-width: breakpoint("small") - 1px) {
      width: 33.333%;
      padding: 0 size("gutter--small") / 3;
    }

    @media (max-width: breakpoint("tiny") - 1px) {
      width: 50%;
    }
  }

  .image {
    width: 100%;
    min-height: 1px;
  }

  .category {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: color("text");

    img {
      transition: transform transition("duration--atoms") transition("ease-ease"),
                  opacity transition("duration--atoms") transition("ease-ease");
      overflow: hidden;
    }

    &:hover {
      img {
        transform: scale(1.03);
        opacity: 0.8;
      }
    }

    span {
      padding: size("gutter--small") 0;
      font-size: size("font--small");
      color: color("text");

      @media (max-width: breakpoint("small") - 1px) {
        padding: size("gutter--mini") 0;
        line-height: font("line-height");
      }
    }

    .image {
      overflow: hidden;
    }

    img {
      display: block;
      width: 100%;
      min-height: 1px;
    }
  }
}
