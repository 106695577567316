
@use "sass:math";

@import "ui-components/_variables";
@import "ui-components/_mixins";
@import "../../node_modules/@crossroads/shop-views/src/shop-views/SuccessView.scss";

.checkIconBG {
  background-color: color("positive");
  padding: size("gutter--mini");
  border-radius: 50%;
  margin-right: size("gutter");
  
  svg {
    width: 24px;
    height: 24px;
  }
}

.mapText {
  padding: size("gutter");
}