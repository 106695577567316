@import "theme/ui-components/_variables.scss";

.block {
  display: flex;
  align-items: center;
  background: #fff;
  height: size("header-height");
  color: color("text");
  margin-bottom: size("header-margin");

  & > div {
    flex: 1 0 auto;
  }

  svg {
    color: color("text");
  }
}

.logo {
  color: color("text");
  text-decoration: none !important;
  margin-right: size("gutter--large");
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left {
  display: flex;
  align-items: center;
}

.right {
  svg {
    height: 34px;
    width: 34px;
  }
}

.steps__desktop {
  display: flex;
  align-items: center;
  justify-content: center;

  .step {
    padding: 0 0.8rem;
    cursor: pointer;
    color: rgba(color("text"), .6);

    &.current {
      color: color("text");
      text-decoration: underline;
    }

    &.disabled {
      cursor: not-allowed;
      color: rgba(color("text"), .6);
    }
  }

  @media (max-width: breakpoint("small") - 1px) {
    display: none;
  }
}

.steps__mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: breakpoint("small")) {
    display: none;
  }

  .step {
    text-align: center;
    margin: 0;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }

  .counter {
    font-size: size("font--mini");
    line-height: font("line-height");
  }

  .title {
    margin-top: size("gutter--small") / 3;
  }

  .back {
    position: absolute;
    left: 0;
    transform: rotate(90deg);
    padding: size("gutter");
    cursor: pointer;
    user-select: none;
  }
}

.left, .right {
  @media (max-width: breakpoint("small") - 1px) {
    display: none;
  }
}
