@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";


.block {
  font-size: size("font--small");
  line-height: font("line-height--breadcrumbs");
  color: color("text");
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.link {
  color: color("primary");
  text-decoration: underline;
}

.current {
  display: inline-block;
  color: color("text");
}

.hasVariant.white {
  color: #fff;

  .link {
    color: #fff;
  }

  .current {
    color: #fff;
  }
}
