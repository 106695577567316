@import "theme/ui-components/_variables.scss";

$view-transition-ease: transition("ease-ease");
$view-transition-duration: transition("duration--slow");

:export {
  viewDuration: $view-transition-duration;
}

.block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.top {
  flex: 1 0 auto;
  padding-bottom: size("gutter--large") * 3;

  .onCheckout, .onPurchasePoints & {
    padding-bottom: 0;
  }
}

.footer {
  flex: 0 0 auto;
}

.notificationBar {
  background-color: color("secondary");
  color: color("OOC--color");
  border-color: color("OOC--color");
}

:global {
  .leave {
    width: 100%;
    opacity: 1;
    position: absolute;
  }

  // .leave:not(.leaving) * {
  .leave * {
    animation: none !important;
    opacity: 1 !important;
  }

  .leaving {
    opacity: 0;
    transition: $view-transition-duration opacity $view-transition-ease;
  }

  .enter {
    opacity: 0;
  }

  .entering {
    opacity: 1;
    transition: $view-transition-duration opacity $view-transition-ease;
  }
}

.height {
  height: size("header-height");
  margin-bottom: size("header-margin");

  @media (max-width: breakpoint("tiny")) {
    height: size("header-height");
  }

  &.onCheckout {
    @media (max-width: breakpoint("tiny-small")) {
      height: 0;
    }
  }
}

/* Footer styling */

.termsField {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: size("grid-gutter");

  @media (max-width: breakpoint("tiny")) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.logo {
  width: fit-content;

  @media (min-width: breakpoint("tiny")) {
    margin-left: auto;
    align-items: end;
  } 
}
