@import "../../node_modules/@crossroads/shop-views/src/shop-views/ProductListHero";

@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$padding: size("gutter--large");
$padding--small: size("gutter");

.content {
  padding: 0 $padding;

  @media (max-width: breakpoint("small")) {
    padding: 0 $padding--small;
  }
}

.text {
  .title {
    display: none;
  }
}