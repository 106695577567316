@import "theme/ui-components/_variables.scss";

$input-width: 47px;
$icon-size: 12px;
$min-width: 135px;
$max-width: 180px;

.block {
  max-width: $max-width;
}

.wrapper {
  display: flex;
  border: 1px solid color("border--muted");
  border-radius: size("border-radius");
  overflow: hidden;
}

.increment, .decrement, .input {
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.increment {
  border-left: 1px solid color("border--muted");
}

.decrement {
  border-right: 1px solid color("border--muted");
}

.increment, .decrement {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: size("gutter--small");
  background: #fff;

  svg {
    color: color("text");
    width: $icon-size;
    height: $icon-size;
  }

  &:disabled, &:disabled:hover {
    background: color("border");
  }
  &:hover {
    opacity: .8;
    //background: color("background--hover");
  }
  &:active {
    opacity: .5;
  }
}

.input {
  border: none;
  width: $input-width;
  flex: 0 0 auto;
}
