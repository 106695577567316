/*
  fonts.scss
  ------------------------------------------------------------

  Custom font faces goes here.

  Format them like this:

  @font-face {
    font-family: "Name";
    font-weight: 400;
    font-style: normal;
    src: url("../../static/assets/fonts/fontname.woff")  format("woff"),
         url("../../static/assets/fonts/fontname.woff2") format("woff2"),
         url("../../static/assets/fonts/fontname.ttf")   format("truetype");
  }

  Use the same family name but different font weights for
  different weights of the same family. In other words; DON'T
  name font faces with another weight as "name-regular",
  "name-bold" or similar. Just use the same name but a
    different weight.
*/

@font-face {
  font-family: "FSAlbert";
  font-weight: 400;
  font-style: normal;
  src: url("assets/fonts/OKQ8Web-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "FSAlbert";
  font-weight: 700;
  font-style: normal;
  src: url("assets/fonts/OKQ8Web-Bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "FSAlbert";
  font-weight: 800;
  font-style: normal;
  src: url("assets/fonts/OKQ8Web-ExtraBold.woff2") format("woff2");
  font-display: swap;
}


// Somehow some part of our build system likes to ignore the font if it's not used in the same file
.foo { font-family: "FSAlbert" }
