@import "../../node_modules/@crossroads/shop-views/src/shop-views/AdventCalendar";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$contentPaddingBottom: size("gutter--mini") + size("gutter--large") * 2;
$containerPadding: 16rem;
$containerPaddingMini: 19rem;
$containerPaddingTiny: 22rem;

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
}

.today {
  &Inner {
    padding: size("gutter") * 3;
    margin-bottom: size("gutter--large") * 2;
    background-color: #fff;
    display: flex;
    align-items: stretch;

    @media (max-width: breakpoint("small")) {
      flex-wrap: wrap;
      padding: 0;
    }
  }

  @media (max-width: breakpoint("small")) {
    flex-direction: column;
    align-items: center;

    &Image {
      margin-bottom: size("gutter--large");
    }
  }

  &Container {
    order: -1;
    width: 50%;
    background-color: color("body");
    padding: size("gutter") * 2;
    display: flex;
    flex-direction: column;

    @media (max-width: breakpoint("small")) {
      background-color: #fff;
      order: 1;
      width: 100%;
    }
  }

  &Banner {
    width: 50%;
    height: 400px;
    overflow: hidden;

    @media (max-width: breakpoint("small")) {
      width: 100%;
    }
  }

  &SmallBanner,
  &MediumBanner,
  &LargeBanner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    position: absolute;
    top: -#{size("header-margin")};
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
  }

  &SmallBanner {
    @media (max-width: 440px) {
      display: block;
    }
  }

  &MediumBanner {
    @media (min-width: 441px) and (max-width: 800px) {
      display: block;
    }
  }

  &LargeBanner {
    @media (min-width: 801px) {
      display: block;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    z-index: 1;
    flex: 1 1 auto;
    margin-top: size("gutter--large") * 1.2;

    @media (max-width: breakpoint("small")) {
      margin-top: 0;
    }

    h3 {
      color: color("primary");
      font-size: size("font--huge");
      text-transform: none;
      margin-bottom: size("gutter");

      @media (max-width: breakpoint("small")) {
        margin-top: 0;
      }
    }

    h2 {
      font-size: size("font--enormous");
      margin-top: 0;
      margin-bottom: size("gutter");
    }

    p {
      margin-top: 0;
    }


  }

  &Description {
    padding: size("gutter--mini");
    max-width: 500px;
    margin-bottom: size("gutter--large") * 1.5;
    background: color("secondary");
    color: #fff;
    text-align: center;
  }

  &ProductList {
    margin-top: size("gutter");

    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }

  &ViewAll {
    margin-top: auto;
    display: block;
  }
}

.hero {
  margin-top: -#{size("header-margin")};
  height: 400px;
  background: url("/assets/advent-hero-2022.jpg") right top / cover no-repeat;
}

.heroContent {
  max-width: 900px;
  padding: size("gutter") * 2;
  padding-left: size("gutter") * 3;
  padding-right: size("gutter") * 3;
  width: 100%;
  background-color: #fff;
  position: relative;
  top: -77px;
  margin:0 auto -#{size("gutter--large")};
  text-align: center;

  h1 {
    margin-top: 0;
    font-size: size("font--huge");
    color: color("primary");
  }

  p {
    font-size: size("font--medium");
  }
}

.button {
  pointer-events: none;

  &:hover {
    background: color("primary") !important;
  }

  .door:active & {
    color: rgba(#fff, .5);
    background-color: color("primary", -10%);
    background-size: 100%;
    transition: background 0s;
  }

  .door:hover & {
    background-color: color("primary", 10%);
    background: color("primary", 10%) radial-gradient(circle, transparent 1%, color("primary", 10%) 1%) center/15000%;
  }
}

.landing {
  margin-top: -#{size("header-margin")};
  background-color: color("secondary");

  &>* {
    flex: 1 1 auto;
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: min(375px, 70%);
    background-position: center top;

    @media (min-width: breakpoint("tiny-small")) {
      background-position: right center;
      background-size: 450px;
    }
  }

  &.fullSize {
    min-height: calc(100vh - #{size("header-height")});
  }

  @media (min-width: breakpoint("tiny-small")) {
    display: flex;
    align-items: center;
  }

  @media (max-width: (breakpoint("tiny-small") - 1px)) {
    padding-top: size("gutter");
  }

  &,
  h1 {
    color: #fff;
  }

  h1 {
    margin-bottom: size("gutter");

    @media (max-width: (breakpoint("small") - 1px)) {
      font-size: size("font--huge");
    }
  }

  p {
    margin-top: 0;
    margin-bottom: size("gutter--large");
    max-width: 600px;
  }

  &Container {
    display: flex;
    flex-direction: column;
    padding: $containerPadding 0;
    padding-bottom: 0;

    @media (min-width: breakpoint("mini")) {
      padding: $containerPaddingMini 0;
      padding-bottom: 0;
    }

    @media (min-width: breakpoint("tiny")) {
      padding: $containerPaddingTiny 0;
      padding-bottom: 0;
    }

    @media (min-width: breakpoint("tiny-small")) {
      padding: size("gutter--section") 0;
    }

    @media (max-width: (breakpoint("medium") - 1px)) {
      flex-direction: column;
    }
  }
}