@import "theme/ui-components/_variables.scss";

.block {
  background-size: cover;
  background-position: center;
  position: relative;
  text-align: center;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  display: block;

  @media (max-width: breakpoint("tiny")) {
    top: inherit;
    padding: size("gutter--mini") / 2 0;

  }

  img {
    width: 100%;
    transition: transform transition("duration--templates") ease;
  }

  button {
    transition: background transition("duration--atoms") ease;
  }

  a {
    position: initial;
    &:hover:active {
      opacity: initial;
    }
  }

  @media (min-width: breakpoint("tiny")) {
    &:hover {
      img {
        transform: scale(1.03);
      }

      button {
        background: color("primary--hover");
      }
    }
  }
}

.text {
  color: white;
}

.size__small {
  font-size: 32px;

  @media (max-width: breakpoint("small")) {
    font-size: 24px;
  }
}

.size__large {
  font-size: 48px;
}

.image {
  object-fit: cover;

  @media (max-width: breakpoint("tiny")) {
    img {
      width: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }
}

.content {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;

  @media (max-width: breakpoint("small")) {
    bottom: -30px;
  }

  @media (max-width: breakpoint("tiny")) {
    flex-direction: inherit;
    margin: inherit;
    left: inherit;
    transform: inherit;
    width: inherit;
    bottom: 10px;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 50px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: auto;
    }
  }

  .text {
    line-height: 1;
    font-weight: 800;
    margin: size("gutter") 0;
  }

  @media (max-width: breakpoint("tiny")) {
    button {
      display: none;
    }

    .text {
      background: #fff;
      color: #000;
      font-size: size("font--small");
      font-weight: inherit;
      padding: size("gutter--mini")
    }
  }
}
