@import "../../node_modules/@crossroads/ui-components/src/ui-components/Button.scss";

$button-height--medium: 44px;

.button {
  border: none;

  &.primary {
    @include commonButton(
      $background: color("primary"),
      $color: #fff,
      $br: size("border-radius"),
      $radial: false,
    );

    box-shadow: none;
    font-size: size("font");
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: font("uc-letter-spacing");

    &:active:hover {
      background-size: inherit !important;;
      transition: inherit !important;;
    }

    &:disabled {
      background: color("disabled") !important;
      border-color: color("disabled") !important;
      color: rgba(color("text"), .35) !important;
    }
  }

  &.secondary {
    @include commonButton(
      $background: color("secondary"),
      $color: #fff,
      $br: size("border-radius"),
      $radial: false,
    );

    transition: background 0.1s;
    font-size: size("font");

    &:active:hover {
      background-size: inherit !important;;
      transition: inherit !important;;
    }
  }

  &.secondarydark {
    @include commonButton(
      $background: #486fe3,
      $color: #fff,
      $br: size("border-radius"),
      $radial: false,
    );

    transition: background 0.1s;
    font-size: size("font");
  }

  &.blank {
    padding: size("gutter--mini") * 1.1 size("gutter--large");
    color: color("primary");

    &:enabled:active:hover {
      text-decoration: underline;
    }
  }

  &.negative {
    @include commonButton(
      $background: #fff,
      $color: color("primary"),
      $br: size("border-radius"),
      $radial: false,
    );

    transition: background 0.1s;

    &:enabled {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.ghost {
    font-size: size("font");
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid color("border--muted");
  }
}

.hasVariant {
  &.size {
    &__large {
      padding: size("gutter--mini") * 1.17 size("gutter--large") size("gutter--mini") * 1.1;

    }

    &__medium {
      height: $button-height--medium;
      padding: 0 size("gutter");
    }

    &__small {
      padding: size("gutter--mini") * .65 size("gutter--small") size("gutter--mini") * .5;
      font-size: size("font");
    }
  }

  &.link {
    padding: 0;
      //@extend %commonTransition;
    color: color("primary");
    text-decoration: none;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(#fff, 0);
    cursor: pointer; /* [4] */
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: 0;
    }

    &:active:hover {
      opacity: .5;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }

  &.ghost_white {
    @include commonButton(
      $color: #fff,
      $background: inherit,
      $borderColor: #fff,
      $br: size("border-radius"),
    );

    font-size: size("font");

    &:enabled {
      &:hover {
        border-color: darken(#fff, 10);
        color: darken(#fff, 10);
      }

      &:active:hover {
        border-color: darken(#fff, 20);
        color: darken(#fff, 20);
      }

      html[data-whatintent="keyboard"] &:focus {
        border-color: darken(#fff, 10);
        color: darken(#fff, 10);
      }
    }
  }

  &.arrow {
    padding: size("gutter--mini") * 1.1 size("gutter--large") size("gutter--mini") * 1.1;
    color: color("primary");
    font-size: size("font");
    padding-left: 0 !important;

    &:enabled:active:hover {
      color: color("primary", 30%);
    }
    &:hover {
      text-decoration: none !important;
    }
    .slotLeft {
      padding: size("gutter--mini") / 1.3;
      border-radius: 50%;
      background-color: color("primary");
      color: #fff;

      svg {
        left: 1px;
        width: 18px;
        height: 18px;
        transform: rotate(180deg);
      }
    }
    &:hover .slotLeft svg {
      animation: moveArrow .4s linear;
    }
  }
}

@keyframes moveArrow {
  0%{
    transform: translateX(0) rotate(180deg);
  }
  33%{
    opacity:0;
    transform: translateX(20px) rotate(180deg);
  }
  66%{
    opacity:0;
    transform: translateX(-20px) rotate(180deg);
  }
}


