@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$hintRowHeight: 250px;
$rowHeight: 350px;
$wrapperPadding: 1.2rem;
$wrapperPaddingLargeX: size("gutter--large");
$wrapperPaddingLargeY: size("gutter--large") * 1.2;

.block, .blockHint {
  // margin-top: -#{size("header-margin")};
  color: color("text");

  &.short {
    background: transparent;
  }
}

.innerBlock {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  color: color("text");
}

.blockHint {
  min-height: $hintRowHeight;
}

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  padding: 0px;

  @media (min-width: breakpoint("small")) {
    flex-direction: row;
  }
}

.text {
  padding: size("gutter--small") 0 0;

  span {
    font-weight: 700;
  }

  .gotImage & {
    padding: 0;
  }

  .title {
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: size("gutter--large") * 1.25;
  }

  .subTitle {
    font-size: size("font--medium");
  }
}

.content {
  flex: 0 0 50%;
  padding: 0 $wrapperPadding;

  @media (min-width: breakpoint("small")) {
    .gotImage & {
      min-height: $rowHeight;
    }
  }

  @media (min-width: breakpoint("medium")) {
    padding: 0 $wrapperPaddingLargeX 0;
  }
}

.imageContainer {
  overflow: hidden;
  height: auto;
  width: auto;

  @media (min-width: breakpoint("small")) {
    position: absolute;
    top: 0;
    right: 0px;
    width: 50%;

    .gotImage & {
      height: 100%;
    }

    .image {
      height: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;

      img {
        height: 100%;
        width: auto;
        max-width: none;
      }
    }
  }
}

.delimeter {
  margin: 0 size("gutter--mini") / 2;
}
