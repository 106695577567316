@import "../../node_modules/@crossroads/ui-components/src/ui-components/ActiveFilters.scss";
@import "theme/ui-components/_variables.scss";

$pill-height: 31px;

.activeFiltersLeft {
  margin-left: -#{size("gutter--mini")};
  padding: size("gutter--mini") 0;
}

.activeFilter {
  padding: size("gutter--mini") size("gutter--large");
  margin: size("gutter--mini") / 2;

  flex-direction: row-reverse;
  align-items: center;
  border: 1px solid color("border--muted");
  background: color("border--muted");
  border-radius: $pill-height;
  height: $pill-height;
  padding: 0 size("gutter--mini");
  padding-right: size("gutter--mini") / 1.5;

  &:hover {
    background: #fff;

    .icon {
      background: color("negative");
    }
  }

  .text {
    margin-left: 0;
    margin-right: 8px;
    font-size: size("font--mini");
  }

  .icon {
    border-radius: 50%;
    background: color("text");
  }
}
