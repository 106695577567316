@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

$top-space: 35px;
$top-space--mobile: 25px;

.block {
  // negate app footer margin and apply here instead to keep background color
  padding-bottom: #{size("gutter--large") * 1.5};
  margin-top: -#{size("header-margin")};
}

.popularBrands, .popularCategories, .popularProducts {
  text-align: center;
  padding: size("gutter") 0;
  content-visibility: auto;

  .link {
    padding: size("gutter") 0 size("gutter-large");
  }

  .box {
    transition: transform transition("duration--atoms") transition("ease-in"),
    box-shadow transition("duration--atoms") transition("ease-in");

    &:hover {
      box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-3px);
    }
  }

  @media (max-width: breakpoint("tiny")) {
    padding: size("gutter") size("gutter--mini");
  }
}

.popularProducts {
  padding: 0;

  .products {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: breakpoint("medium")) {
      justify-content: center;
    }

    .product {
      flex: 1 0 25%;
      width: 25%;
      padding: size("grid-gutter") / 2;

      &:nth-child(1) {
        padding-left: 0;
      }

      @media (max-width: breakpoint("medium")) {
        flex: 1 0 33.33%;
        width: 33.33%;
      }

      @media (max-width: breakpoint("tiny")) {
        flex: 1 0 100%;
      }
    }
  }

  .cta {
    display: block;
    margin: size("gutter") 0 size("gutter");
    flex: 0 1 auto;
  }
}

.popularCategories {
  padding-bottom: 0;

  @media (max-width: breakpoint("tiny")) {
    padding: 0;
  }
}

.logos {
  display: flex;
  flex-wrap: wrap;

  img {
    height: 100px;

    @media (max-width: breakpoint("tiny")) {
      height: 50px;
    }
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 21%;
    margin: size("grid-gutter") / 2;
    min-width: 100px;
    height: 150px;
    border-radius: 3px;
    border: solid 1px color("border");

    @media (max-width: breakpoint("tiny")) {
      flex: 1 0 33%;
      height: 100px;
    }

    @media (max-width: breakpoint("tiny")) {
      height: 80px;
    }
  }
}

.desktop {
  @media (max-width: breakpoint("tiny")) {
    display: none;
  }
}

.popularBrandsButton {
  margin: size("gutter") 0 size("gutter");
  flex: 0 1 auto;
}

.double {
  display: flex;
  justify-content: space-between;
  flex: 0 1 auto;
  margin: size("gutter") * 2 0;

  > a {
    width: 50%;

    @media (min-width: breakpoint("small")) {
      &:nth-child(1) {
        margin-right: size("gutter--large") * 1.5 !important;
      }
    }

  }

  a:first-of-type {
    margin-right: size("gutter--mini");
  }

  @media (max-width: breakpoint("tiny")) {
    display: inherit;
    flex: inherit;
    margin: 0;

    > a {
      width: inherit;
    }

    a:first-of-type {
      margin-right: inherit;
    }
  }
}

.triple {
  display: flex;
  flex: 0 1 auto;
  margin-top: size("gutter--medium") * 2;
  margin-bottom: size("gutter") * 3;

  > a {
    width: 33%;
  }

  a:not(:last-of-type) {
    margin-right: size("gutter--mini");
  }

  @media (max-width: breakpoint("tiny")) {
    display: inherit;
    flex: inherit;
    margin: 0;
    margin-top: size("gutter");

    > a {
      width: inherit;
    }

    a:not(:last-of-type) {
      margin-right: 0;
    }
  }
}

.carouselControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: size("gutter");
}

.categoriesContainer {
  display: flex;
  justify-content: space-between;
}

  .title {
    color: color("primary");
  }