@import "theme/ui-components/_variables.scss";

$content-margin: size("gutter--section");
$content-margin--medium: size("gutter--section") / 2;
$content-margin--small: size("gutter--large");
$hero-height: 360px;
$hero-height-small: 260px;


.block {
  background-color: #fff !important;
  width: 100%;
  top: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: $hero-height;

  @media (max-width: breakpoint("small")) {
    height: $hero-height-small;
  }

  @media (max-width: breakpoint("small")) {
    justify-content: center;
  }

  img {
    object-fit: cover;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      position: absolute;
    }
  }
}

.image {
  display: flex;
  object-fit: cover;
  width: 50%;
  z-index: 10;

  @media (max-width: breakpoint("small")) {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  img {
    height: $hero-height;
    width: auto;
  }
}

.wrapper {
  width: 50%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 11;

  @media (max-width: breakpoint("small")) {
    width: 100%;
    max-width: 95%;
    justify-content: center;
    margin: 0;
    padding: size("gutter");
  }
}

.contentBlock {
  position: relative;
  background-color: transparentize(color("background"), .1);
  margin: (size("gutter--large") * 2) 0;
  padding: size("gutter--large") * 2 0 size("gutter--small") size("gutter--large") * 2;

  @media (max-width: breakpoint("small")) {
    padding: size("gutter--medium") size("gutter--large") * 1.4;
  }
}

.heading {
  color: color("primary");
  margin: size("gutter--tiny") 0;
  font-weight: 600;
  font-size: size("font--huge");
  line-height: font("line-height") / 1.4;
  max-width: 600px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: size("gutter");
  }

  @media (min-width: breakpoint("small")) {
    font-size: size("font--enormous") * 1.3;
    max-width: 400px;
  }
}

.subHeading {
  color: color("text");
  margin: size("gutter--tiny") 0;
  font-size: size("font--large");
  line-height: font("line-height") / 1.1;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: size("gutter");
  }

  @media (min-width: breakpoint("small")) {
    font-size: size("font--huge");
  }
}

.splash {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 150px;
  transform: rotate(-25deg);
  left: 15px;
  top: -40%;

  > h3 {
    max-width: 80%;
    font-weight: font("weight--bold");
    font-size: size("font--mini");
  }

  @media (max-width: breakpoint("small")) {
    max-width: 130px;
    transform: rotate(25deg);
    left: 70%;
    top: -50%;
  }
}

.text {
  color: color("text");
  margin: size("gutter") 0;
  font-size: size("font");
  line-height: font("line-height") / 1.1;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: size("gutter");
  }

  @media (min-width: breakpoint("small")) {
    font-size: size("font--medium");
  }
}

.cta {

  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 7px);
    left: 12px;
    width: 9px;
    height: 14px;
    background-repeat: no-repeat;
    background-size: 9px 14px;
  }
}

.slotLeft {
  border-radius: 50%;
  background-color: color("primary");
  color: #fff;
}
