@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
}

.searchResult {
  margin-bottom: size("gutter");
}

.searchLists {
  h3 {
    margin-top: size("gutter--large");
    color: color("text");
  }
}

.filterbar {
  z-index: z-index("popup");
}
