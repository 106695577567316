@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";
@import "../../node_modules/@crossroads/ui-components/src/ui-components/AppFooter";

.cols > div {
  @media (min-width: (breakpoint("tiny"))) and (max-width: (breakpoint("small") - 1px)) {
    &:nth-child(2) {
      text-align: end;
      nav {
        align-items: flex-end;
      }
    }
  }
}
